.main-container{
    padding: 70px
}
.add-button{
    position: fixed !important ;
    top:60px;
    right:20px ;
}
.textField {
    width:100%;

}
.countrycode-dropdown{
    display: flex;
    justify-content: space-between;
    width: 90%;
}
.row{
    display: flex;
}
.cube{
    width: 20px;
    height: 20px;
    background-color: #f5a7a7;
    margin-right: 20px;
}
  @media (max-width: 768px){

}
@media (max-width: 576px)
{
  
    
}