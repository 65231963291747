.content-loader{
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
    width: 100vw;
    height: -webkit-fill-available;
    height: 100vh;
    z-index: 200;
    position: fixed;

}
.content-loader svg{
    -webkit-flex: 1; /* Safari 6.1+ */
    flex: 1;
}
@media only screen and (min-width: 320px) and (max-width: 767px)  {
    
    .content-loader{
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction: column;
        align-items: center;
        -webkit-align-items: center; /* Safari 7.0+ */
        width: 100%;
        height:-webkit-fill-available;

    }
}