.country-details-container{
    display: flex;
    justify-content: space-between;
}
.section-wrapper{
    border: 1px solid #dad5d5;
    border-radius: 8px;
    padding: 20px;
}
.container{
    width: 60% !important;
    margin-top: 40px;
}
.title{
    color:#1f4068
}
.textField{
    width: 100px;
}
.add-button{
    height: 50px;
}
.modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content{
    background-color: aliceblue;
    padding: 20px;
    min-width: 50%;
    min-height: 300px;
}
.add-new-country-form{
    display: flex;
    flex-direction: column;
}
.action-containar{
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
}
.margin-top{
    margin-top: 30px;
}
.mapped-lang{
    display: flex;
    margin: 30px;
}
.lang-code{
    background: #63b7af;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    color: #ffff;
    position: relative;
}
.lang-map-container{
    display: flex;
    justify-content: space-between;
}
.default{
    background-color:#d4f3ef;
    color:#51968f
}
.direction-column{
    display: flex;
    flex-direction: column;
}
.close-icon{
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 17px !important;
    cursor: pointer;
}
.icon-text{
    height: 30px;
    width: 30px;
    text-align: center;
    margin-top: 11px;
    cursor: pointer;
    text-transform: uppercase;
}
.main-lang-title{
    color: gray;
    border-bottom: 1px solid;
}
.main-lang-value{
    margin-top: 20px;
    text-transform: uppercase;
}
.add-icon{
    color: gray;
    font-size: 50px;
    cursor: pointer;
}
.add-lang-container{
    position: relative;
}
.lang-list{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 40px;
    right: -10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    height: 200px;
    overflow: auto;
}
.lang-text{
    text-transform: uppercase;
    border-bottom: 1px solid darkgray;
    padding: 10px;
    width: 60px;
    text-align: center;
    cursor: pointer;
}