.login{
    /* input[type=number]::-webkit-inner-spin-button;
    input[type=number]::-webkit-outer-spin-button 
    { 
        -webkit-appearance: none; 
        margin: 0; 
    } */
    /* input[type="number"]:-webkit-outer-spin-button;
     input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
} */


    background-color:white;
    height:100vh;
    color:black;
    overflow-y: hidden;
    overflow-x: hidden;
}

.login-page-header{
    background: black;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #d0d5da;
    align-items: center;
}
.login-page-content{
    display: flex;
    padding: 30px 60px;
    text-align: center;
    width: 100%;
    justify-content: center
}
.login-page-content h1{
    font-family: 'AvenirNext-DemiBold' !important;
    font-size: 18px;
    color: #8e8d8e !important;
    font-weight: normal;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
}
.login-page-content p{
    font-size: 13px;
    color: #8e8d8e;
    margin-bottom: 20px;
}

.login-page-mobile_num{
    display: flex;
    flex-direction: column;
    width:30%
}
.LoginRoute-number{
    border: 1px solid #8e8d8e;
    padding: 11px 10px;
    border-radius: 25px;
    font-size: 15px;
    margin-bottom: 15px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #8e8d8e;
    outline: none;
}

.login-page-mobile_num .LoginRoute-button {
    background: #e2505c;
    color: #fffeff;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 10px;
    border-radius: 25px;
    width: 100%;
    outline: none;
    position: relative;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.login-page-mobile_num .LoginRoute-button:hover {
    background: #f2949b;
}



.footer {
    border-top: 1px solid #e5e5e5;
    color: #8e8d8e;
    text-align: center;
    font-size: 9px;
    padding: 20px;
    position: absolute;
    bottom: -90px;
    height: 90px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}
